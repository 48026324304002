import useBaseUtils from "~/functions/useBaseUtils";
import SubscriptionDetails from "~/components/SubscriptionDetails.vue";
import ShowCurrentSubscription from "~/components/account/ShowCurrentSubscription.vue";
export default defineComponent({
  components: {
    SubscriptionDetails: SubscriptionDetails,
    ShowCurrentSubscription: ShowCurrentSubscription
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      payingCustomer = _useBaseUtils.payingCustomer,
      idtoken = _useBaseUtils.idtoken,
      freeTier = _useBaseUtils.freeTier;
    return {
      payingCustomer: payingCustomer,
      idtoken: idtoken,
      freeTier: freeTier
    };
  },
  computed: {
    dialog: function dialog() {
      return this.$store.state.config.paymentDialog;
    },
    userGroup: function userGroup() {
      return this.idtoken.payload["custom:group"];
    },
    dark: function dark() {
      return this.$vuetify.theme.dark;
    },
    selectedPlanCode: function selectedPlanCode() {
      return this.$store.state.config.selectedPlanCode;
    },
    couponCode: function couponCode() {
      // TODO: this is where you can autoset a coupon code to
      // be based into the payment modal.
      // the coupon code is tied to the plan code as well
      var planCode = this.selectedPlanCode;
      if (planCode === "a-2021_v0-global") {
        return "aloyal4eva";
      } else if (planCode === "m-2021_v0-global") {
        return "mloyal4eva";
      } else if (planCode === "a-2022_v0-global") {
        return "";
      } else if (planCode === "a-2022_v0-plus") {
        return "";
      } else {
        return "";
      }
    }
  },
  mounted: function mounted() {
    try {
      this.$vuetify.goTo(0);
    } catch (error) {//
    }
  }
});