import "core-js/modules/es.array.sort.js";
// const lambdaStage = process.env.LAMBDA_STAGE
// const restrictResponse = process.env.USE_TIERS === "true"
// const devStage = "dev"
var paymentUtils = function paymentUtils() {
  var countryArr = [{
    value: "AC",
    text: "Ascension Island"
  }, {
    value: "AD",
    text: "Andorra"
  }, {
    value: "AE",
    text: "United Arab Emirates"
  }, {
    value: "AF",
    text: "Afghanistan"
  }, {
    value: "AG",
    text: "Antigua and Barbuda"
  }, {
    value: "AI",
    text: "Anguilla"
  }, {
    value: "AL",
    text: "Albania"
  }, {
    value: "AM",
    text: "Armenia"
  }, {
    value: "AO",
    text: "Angola"
  }, {
    value: "AQ",
    text: "Antarctica"
  }, {
    value: "AR",
    text: "Argentina"
  }, {
    value: "AS",
    text: "American Samoa"
  }, {
    value: "AT",
    text: "Austria"
  }, {
    value: "AU",
    text: "Australia"
  }, {
    value: "AW",
    text: "Aruba"
  }, {
    value: "AX",
    text: "Åland Islands"
  }, {
    value: "AZ",
    text: "Azerbaijan"
  }, {
    value: "BA",
    text: "Bosnia and Herzegovina"
  }, {
    value: "BB",
    text: "Barbados"
  }, {
    value: "BD",
    text: "Bangladesh"
  }, {
    value: "BE",
    text: "Belgium"
  }, {
    value: "BF",
    text: "Burkina Faso"
  }, {
    value: "BG",
    text: "Bulgaria"
  }, {
    value: "BH",
    text: "Bahrain"
  }, {
    value: "BI",
    text: "Burundi"
  }, {
    value: "BJ",
    text: "Benin"
  }, {
    value: "BL",
    text: "Saint Barthélemy"
  }, {
    value: "BM",
    text: "Bermuda"
  }, {
    value: "BN",
    text: "Brunei"
  }, {
    value: "BO",
    text: "Bolivia"
  }, {
    value: "BQ",
    text: "British Antarctic Territory"
  }, {
    value: "BR",
    text: "Brazil"
  }, {
    value: "BS",
    text: "Bahamas"
  }, {
    value: "BT",
    text: "Bhutan"
  }, {
    value: "BV",
    text: "Bouvet Island"
  }, {
    value: "BW",
    text: "Botswana"
  }, {
    value: "BY",
    text: "Belarus"
  }, {
    value: "BZ",
    text: "Belize"
  }, {
    value: "CA",
    text: "Canada"
  }, {
    value: "CC",
    text: "Cocos [Keeling] Islands"
  }, {
    value: "CD",
    text: "Congo [Democratic Republic]"
  }, {
    value: "CF",
    text: "Central African Republic"
  }, {
    value: "CG",
    text: "Congo"
  },
  // {
  //   value: "CG",
  //   text: "Congo [Republic]",
  // },
  {
    value: "CH",
    text: "Switzerland"
  }, {
    value: "CI",
    text: "Côte d’Ivoire / Ivory Coast"
  },
  // {
  //   value: "CI",
  //   text: "Ivory Coast",
  // },
  {
    value: "CK",
    text: "Cook Islands"
  }, {
    value: "CL",
    text: "Chile"
  }, {
    value: "CM",
    text: "Cameroon"
  }, {
    value: "CN",
    text: "China"
  }, {
    value: "CO",
    text: "Colombia"
  }, {
    value: "CR",
    text: "Costa Rica"
  }, {
    value: "CU",
    text: "Cuba"
  }, {
    value: "CV",
    text: "Cabo Verde"
  }, {
    value: "CX",
    text: "Christmas Island"
  }, {
    value: "CY",
    text: "Cyprus"
  }, {
    value: "CZ",
    text: "Czech Republic"
  }, {
    value: "DE",
    text: "Germany"
  }, {
    value: "DG",
    text: "Diego Garcia"
  }, {
    value: "DJ",
    text: "Djibouti"
  }, {
    value: "DK",
    text: "Denmark"
  }, {
    value: "DM",
    text: "Dominica"
  }, {
    value: "DO",
    text: "Dominican Republic"
  }, {
    value: "DZ",
    text: "Algeria"
  }, {
    value: "EC",
    text: "Ecuador"
  }, {
    value: "EE",
    text: "Estonia"
  }, {
    value: "EG",
    text: "Egypt"
  }, {
    value: "EH",
    text: "Western Sahara"
  }, {
    value: "ER",
    text: "Eritrea"
  }, {
    value: "ES",
    text: "Spain"
  }, {
    value: "ET",
    text: "Ethiopia"
  }, {
    value: "FI",
    text: "Finland"
  }, {
    value: "FJ",
    text: "Fiji"
  }, {
    value: "FK",
    text: "Falkland Islands"
  },
  // {
  //   value: "FK",
  //   text: "Falkland Islands [Malvinas]",
  // },
  {
    value: "FM",
    text: "Micronesia"
  }, {
    value: "FO",
    text: "Faroe Islands"
  }, {
    value: "FR",
    text: "France"
  }, {
    value: "GA",
    text: "Gabon"
  }, {
    value: "GB",
    text: "United Kingdom"
  }, {
    value: "GD",
    text: "Grenada"
  }, {
    value: "GE",
    text: "Georgia"
  }, {
    value: "GF",
    text: "French Guiana"
  }, {
    value: "GG",
    text: "Guernsey"
  }, {
    value: "GH",
    text: "Ghana"
  }, {
    value: "GI",
    text: "Gibraltar"
  }, {
    value: "GL",
    text: "Greenland"
  }, {
    value: "GM",
    text: "Gambia"
  }, {
    value: "GN",
    text: "Guinea"
  }, {
    value: "GP",
    text: "Guadeloupe"
  }, {
    value: "GQ",
    text: "Equatorial Guinea"
  }, {
    value: "GR",
    text: "Greece"
  }, {
    value: "GS",
    text: "South Georgia and the South Sandwich Islands"
  }, {
    value: "GT",
    text: "Guatemala"
  }, {
    value: "GU",
    text: "Guam"
  }, {
    value: "GW",
    text: "Guinea"
  }, {
    value: "GY",
    text: "Guyana"
  }, {
    value: "HK",
    text: "Hong Kong"
  }, {
    value: "HM",
    text: "Heard Island and McDonald Islands"
  }, {
    value: "HN",
    text: "Honduras"
  }, {
    value: "HR",
    text: "Croatia"
  }, {
    value: "HT",
    text: "Haiti"
  }, {
    value: "HU",
    text: "Hungary"
  }, {
    value: "ID",
    text: "Indonesia"
  }, {
    value: "IE",
    text: "Ireland"
  }, {
    value: "IL",
    text: "Israel"
  }, {
    value: "IM",
    text: "Isle of Man"
  }, {
    value: "IN",
    text: "India"
  }, {
    value: "IO",
    text: "British Indian Ocean Territory"
  }, {
    value: "IQ",
    text: "Iraq"
  }, {
    value: "IR",
    text: "Iran"
  }, {
    value: "IS",
    text: "Iceland"
  }, {
    value: "IT",
    text: "Italy"
  }, {
    value: "JE",
    text: "Jersey"
  }, {
    value: "JM",
    text: "Jamaica"
  }, {
    value: "JO",
    text: "Jordan"
  }, {
    value: "JP",
    text: "Japan"
  }, {
    value: "KE",
    text: "Kenya"
  }, {
    value: "KG",
    text: "Kyrgyzstan"
  }, {
    value: "KH",
    text: "Cambodia"
  }, {
    value: "KI",
    text: "Kiribati"
  }, {
    value: "KM",
    text: "Comoros"
  }, {
    value: "KN",
    text: "Saint Kitts and Nevis"
  }, {
    value: "KP",
    text: "North Korea"
  }, {
    value: "KR",
    text: "South Korea"
  }, {
    value: "KW",
    text: "Kuwait"
  }, {
    value: "KY",
    text: "Cayman Islands"
  }, {
    value: "KZ",
    text: "Kazakhstan"
  }, {
    value: "LA",
    text: "Laos"
  }, {
    value: "LB",
    text: "Lebanon"
  }, {
    value: "LC",
    text: "Saint Lucia"
  }, {
    value: "LI",
    text: "Liechtenstein"
  }, {
    value: "LK",
    text: "Sri Lanka"
  }, {
    value: "LR",
    text: "Liberia"
  }, {
    value: "LS",
    text: "Lesotho"
  }, {
    value: "LT",
    text: "Lithuania"
  }, {
    value: "LU",
    text: "Luxembourg"
  }, {
    value: "LV",
    text: "Latvia"
  }, {
    value: "LY",
    text: "Libya"
  }, {
    value: "MA",
    text: "Morocco"
  }, {
    value: "MC",
    text: "Monaco"
  }, {
    value: "MD",
    text: "Moldova"
  }, {
    value: "ME",
    text: "Montenegro"
  }, {
    value: "MF",
    text: "Saint Martin"
  }, {
    value: "MG",
    text: "Madagascar"
  }, {
    value: "MH",
    text: "Marshall Islands"
  }, {
    value: "MK",
    text: "Macedonia"
  },
  // {
  //   value: "MK",
  //   text: "North Macedonia",
  // },
  {
    value: "ML",
    text: "Mali"
  }, {
    value: "MM",
    text: "Myanmar [Burma]"
  }, {
    value: "MN",
    text: "Mongolia"
  }, {
    value: "MO",
    text: "Macao"
  }, {
    value: "MP",
    text: "Northern Mariana Islands"
  }, {
    value: "MQ",
    text: "Martinique"
  }, {
    value: "MR",
    text: "Mauritania"
  }, {
    value: "MS",
    text: "Montserrat"
  }, {
    value: "MT",
    text: "Malta"
  }, {
    value: "MU",
    text: "Mauritius"
  }, {
    value: "MV",
    text: "Maldives"
  }, {
    value: "MW",
    text: "Malawi"
  }, {
    value: "MX",
    text: "Mexico"
  }, {
    value: "MY",
    text: "Malaysia"
  }, {
    value: "MZ",
    text: "Mozambique"
  }, {
    value: "NA",
    text: "Namibia"
  }, {
    value: "NC",
    text: "New Caledonia"
  }, {
    value: "NE",
    text: "Niger"
  }, {
    value: "NF",
    text: "Norfolk Island"
  }, {
    value: "NG",
    text: "Nigeria"
  }, {
    value: "NI",
    text: "Nicaragua"
  }, {
    value: "NL",
    text: "Netherlands"
  }, {
    value: "NO",
    text: "Norway"
  }, {
    value: "NP",
    text: "Nepal"
  }, {
    value: "NR",
    text: "Nauru"
  }, {
    value: "NU",
    text: "Niue"
  }, {
    value: "NZ",
    text: "New Zealand"
  }, {
    value: "OM",
    text: "Oman"
  }, {
    value: "PA",
    text: "Panama"
  }, {
    value: "PE",
    text: "Peru"
  }, {
    value: "PF",
    text: "French Polynesia"
  }, {
    value: "PG",
    text: "Papua New Guinea"
  }, {
    value: "PH",
    text: "Philippines"
  }, {
    value: "PK",
    text: "Pakistan"
  }, {
    value: "PL",
    text: "Poland"
  }, {
    value: "PM",
    text: "Saint Pierre and Miquelon"
  }, {
    value: "PN",
    text: "Pitcairn Islands"
  }, {
    value: "PR",
    text: "Puerto Rico"
  }, {
    value: "PS",
    text: "Palestinian Territories"
  }, {
    value: "PT",
    text: "Portugal"
  }, {
    value: "PW",
    text: "Palau"
  }, {
    value: "PY",
    text: "Paraguay"
  }, {
    value: "QA",
    text: "Qatar"
  }, {
    value: "RE",
    text: "Réunion"
  }, {
    value: "RO",
    text: "Romania"
  }, {
    value: "RS",
    text: "Serbia"
  }, {
    value: "RU",
    text: "Russia"
  }, {
    value: "RW",
    text: "Rwanda"
  }, {
    value: "SA",
    text: "Saudi Arabia"
  }, {
    value: "SB",
    text: "Solomon Islands"
  }, {
    value: "SC",
    text: "Seychelles"
  }, {
    value: "SD",
    text: "Sudan"
  }, {
    value: "SE",
    text: "Sweden"
  }, {
    value: "SG",
    text: "Singapore"
  }, {
    value: "SH",
    text: "Saint Helena"
  }, {
    value: "SI",
    text: "Slovenia"
  }, {
    value: "SJ",
    text: "Svalbard and Jan Mayen"
  }, {
    value: "SK",
    text: "Slovakia"
  }, {
    value: "SL",
    text: "Sierra Leone"
  }, {
    value: "SM",
    text: "San Marino"
  }, {
    value: "SN",
    text: "Senegal"
  }, {
    value: "SO",
    text: "Somalia"
  }, {
    value: "SR",
    text: "Suriname"
  }, {
    value: "SS",
    text: "South Sudan"
  }, {
    value: "ST",
    text: "São Tomé and Príncipe"
  }, {
    value: "SV",
    text: "El Salvador"
  }, {
    value: "SX",
    text: "Sint Maarten [Dutch part]"
  }, {
    value: "SY",
    text: "Syria"
  }, {
    value: "SZ",
    text: "Swaziland"
  }, {
    value: "TC",
    text: "Turks and Caicos Islands"
  }, {
    value: "TD",
    text: "Chad"
  }, {
    value: "TF",
    text: "French Southern Territories"
  }, {
    value: "TG",
    text: "Togo"
  }, {
    value: "TH",
    text: "Thailand"
  }, {
    value: "TJ",
    text: "Tajikistan"
  }, {
    value: "TK",
    text: "Tokelau"
  }, {
    value: "TL",
    text: "Timor"
  },
  // {
  //   value: "TL",
  //   text: "East Timor",
  // },
  {
    value: "TM",
    text: "Turkmenistan"
  }, {
    value: "TN",
    text: "Tunisia"
  }, {
    value: "TO",
    text: "Tonga"
  }, {
    value: "TR",
    text: "Turkey"
  }, {
    value: "TT",
    text: "Trinidad and Tobago"
  }, {
    value: "TV",
    text: "Tuvalu"
  }, {
    value: "TW",
    text: "Taiwan"
  }, {
    value: "TZ",
    text: "Tanzania"
  }, {
    value: "UA",
    text: "Ukraine"
  }, {
    value: "UG",
    text: "Uganda"
  }, {
    value: "UM",
    text: "U.S. Minor Outlying Islands"
  }, {
    value: "US",
    text: "United States"
  }, {
    value: "UY",
    text: "Uruguay"
  }, {
    value: "UZ",
    text: "Uzbekistan"
  }, {
    value: "VA",
    text: "Vatican City"
  }, {
    value: "VC",
    text: "Saint Vincent and the Grenadines"
  }, {
    value: "VE",
    text: "Venezuela"
  }, {
    value: "VG",
    text: "British Virgin Islands"
  }, {
    value: "VI",
    text: "U.S. Virgin Islands"
  }, {
    value: "VN",
    text: "Vietnam"
  }, {
    value: "VU",
    text: "Vanuatu"
  }, {
    value: "WF",
    text: "Wallis and Futuna"
  }, {
    value: "WS",
    text: "Samoa"
  }, {
    value: "YE",
    text: "Yemen"
  }, {
    value: "YT",
    text: "Mayotte"
  }, {
    value: "ZA",
    text: "South Africa"
  }, {
    value: "ZM",
    text: "Zambia"
  }, {
    value: "ZW",
    text: "Zimbabwe"
  }];
  var usStateArr = [{
    value: "AL",
    text: "Alabama"
  }, {
    value: "AK",
    text: "Alaska"
  }, {
    value: "AS",
    text: "America Samoa"
  }, {
    value: "AZ",
    text: "Arizona"
  }, {
    value: "AR",
    text: "Arkansas"
  }, {
    value: "AA",
    text: "Armed Forces Americas"
  }, {
    value: "AE",
    text: "Armed Forces"
  }, {
    value: "AP",
    text: "Armed Forces Pacific"
  }, {
    value: "CA",
    text: "California"
  }, {
    value: "CO",
    text: "Colorado"
  }, {
    value: "CT",
    text: "Connecticut"
  }, {
    value: "DE",
    text: "Delaware"
  }, {
    value: "DC",
    text: "District of Columbia"
  }, {
    value: "FM",
    text: "Federated States of Micronesia"
  }, {
    value: "FL",
    text: "Florida"
  }, {
    value: "GA",
    text: "Georgia"
  }, {
    value: "GU",
    text: "Guam"
  }, {
    value: "HI",
    text: "Hawaii"
  }, {
    value: "ID",
    text: "Idaho"
  }, {
    value: "IL",
    text: "Illinois"
  }, {
    value: "IN",
    text: "Indiana"
  }, {
    value: "IA",
    text: "Iowa"
  }, {
    value: "KS",
    text: "Kansas"
  }, {
    value: "KY",
    text: "Kentucky"
  }, {
    value: "LA",
    text: "Louisiana"
  }, {
    value: "ME",
    text: "Maine"
  }, {
    value: "MH",
    text: "Marshall Islands"
  }, {
    value: "MD",
    text: "Maryland"
  }, {
    value: "MA",
    text: "Massachusetts"
  }, {
    value: "MI",
    text: "Michigan"
  }, {
    value: "MN",
    text: "Minnesota"
  }, {
    value: "MS",
    text: "Mississippi"
  }, {
    value: "MO",
    text: "Missouri"
  }, {
    value: "MT",
    text: "Montana"
  }, {
    value: "NE",
    text: "Nebraska"
  }, {
    value: "NV",
    text: "Nevada"
  }, {
    value: "NH",
    text: "New Hampshire"
  }, {
    value: "NJ",
    text: "New Jersey"
  }, {
    value: "NM",
    text: "New Mexico"
  }, {
    value: "NY",
    text: "New York"
  }, {
    value: "NC",
    text: "North Carolina"
  }, {
    value: "ND",
    text: "North Dakota"
  }, {
    value: "MP",
    text: "Northern Mariana Islands"
  }, {
    value: "OH",
    text: "Ohio"
  }, {
    value: "OK",
    text: "Oklahoma"
  }, {
    value: "OR",
    text: "Oregon"
  }, {
    value: "PA",
    text: "Pennsylvania"
  }, {
    value: "PR",
    text: "Puerto Rico"
  }, {
    value: "PW",
    text: "Palau"
  }, {
    value: "RI",
    text: "Rhode Island"
  }, {
    value: "SC",
    text: "South Carolina"
  }, {
    value: "SD",
    text: "South Dakota"
  }, {
    value: "TN",
    text: "Tennessee"
  }, {
    value: "TX",
    text: "Texas"
  }, {
    value: "UT",
    text: "Utah"
  }, {
    value: "VA",
    text: "Virginia"
  }, {
    value: "VI",
    text: "Virgin Islands"
  }, {
    value: "VT",
    text: "Vermont"
  }, {
    value: "WA",
    text: "Washington"
  }, {
    value: "WI",
    text: "Wisconsin"
  }, {
    value: "WV",
    text: "West Virginia"
  }, {
    value: "WY",
    text: "Wyoming"
  }];
  var canadaProvArr = [{
    value: "AB",
    text: "Alberta"
  }, {
    value: "BC",
    text: "British Columbia"
  }, {
    value: "MB",
    text: "Manitoba"
  }, {
    value: "NB",
    text: "New Brunswick"
  }, {
    value: "NL",
    text: "Newfoundland"
  }, {
    value: "NT",
    text: "Northwest Territories"
  }, {
    value: "NS",
    text: "Nova Scotia"
  }, {
    value: "NU",
    text: "Nunavut"
  }, {
    value: "ON",
    text: "Ontario"
  }, {
    value: "PE",
    text: "Prince Edward Island"
  }, {
    value: "QC",
    text: "Quebec"
  }, {
    value: "SK",
    text: "Saskatchewan"
  }, {
    value: "YT",
    text: "Yukon"
  }];
  var sortTextAlphabetically = function sortTextAlphabetically(a, b) {
    return a.text.localeCompare(b.text);
  };
  var firstname = ref("");
  var lastname = ref("");
  var phonenumber = ref("");
  var address1 = ref("");
  var address2 = ref("");
  var countryCode = ref("");
  var city = ref("");
  var stateCode = ref("");
  var postalCode = ref("");
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var paymentDialog = computed(function () {
    return $store.state.config.paymentDialog;
  });
  var firstNameRules = ref([function (v) {
    return Boolean(v) || "First name required";
  }]);
  var lastNameRules = ref([function (v) {
    return Boolean(v) || "Last name required";
  }]);
  var termsRules = ref([function (v) {
    return Boolean(v) || "You must agree to continue";
  }]);
  var countryRules = ref([function (v) {
    return Boolean(v) || "Country required";
  }]); // const stateRules = ref([(v) => Boolean(v) || "State required"])
  var postalRules = ref([function (v) {
    return Boolean(v) || "Zip / Postal code required";
  }]);
  var streetAddressRules = ref([function (v) {
    return Boolean(v) || "Street address required";
  }]);
  var cityRules = ref([function (v) {
    return Boolean(v) || "City required";
  }]);
  return {
    countryArr: countryArr.sort(sortTextAlphabetically),
    usStateArr: usStateArr.sort(sortTextAlphabetically),
    canadaProvArr: canadaProvArr.sort(sortTextAlphabetically),
    sortTextAlphabetically: sortTextAlphabetically,
    firstname: firstname,
    lastname: lastname,
    phonenumber: phonenumber,
    address1: address1,
    address2: address2,
    countryCode: countryCode,
    city: city,
    stateCode: stateCode,
    postalCode: postalCode,
    paymentDialog: paymentDialog,
    firstNameRules: firstNameRules,
    lastNameRules: lastNameRules,
    termsRules: termsRules,
    countryRules: countryRules,
    // stateRules,
    postalRules: postalRules,
    streetAddressRules: streetAddressRules,
    cityRules: cityRules
  };
};
export default paymentUtils;